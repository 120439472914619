import React, { ReactNode, useState, MouseEvent, useEffect } from "react";
import { head, isNil, pathOr } from "ramda";
import cx from "classnames";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

import Layout from "../components/Layout/Layout";
import { SEO } from "../components/Seo/Seo";
import { ImageAsset } from "../components/ImageAsset/ImageAsset";
import { Treatment as TreatmentComponent } from "../components/Treatment/Treatment";

import useTreatmentsPageData from "../hooks/useTreatmentsPageData/useTreatmentsPageData";
import { TreatmentsPageData } from "../hooks/useTreatmentsPageData/useTreatmentsPageData.types";

import { Treatment, TreatmentCategory } from "../types/globals";

const TreatmentsPage = (): ReactNode => {
  const { title, categories }: TreatmentsPageData = useTreatmentsPageData();

  const [currentCategory, setCurrentCategory] = useState<TreatmentCategory | undefined>();

  const handleClickCategory = (e: MouseEvent): void => {
    const selected = (e.currentTarget as HTMLButtonElement).dataset.id;

    if (selected) {
      const current = categories.filter(({ id }: TreatmentCategory): boolean => id === selected);
      setCurrentCategory(head(current));
    }
  };

  useEffect(() => {
    if (isNil(currentCategory)) {
      setCurrentCategory(head(categories));
    }
  }, []);

  const renderButton = (id: string, title: string): ReactNode => (
    <button
      className={cx(`p-treatments__button u-heading-small`, currentCategory?.id === id && `is-selected`)}
      title={title}
      key={id}
      data-id={id}
      onClick={handleClickCategory}
    >
      {title}
    </button>
  );

  const renderCategories = (): ReactNode => (
    <div className="p-treatments__categories">
      {categories.map(({ id, title }: TreatmentCategory): ReactNode => renderButton(id, title))}
    </div>
  );

  const renderTreatment = ({ id, title, image, price, duration, description }: Treatment) => (
    <TreatmentComponent
      key={id}
      title={title}
      image={image}
      price={price}
      duration={duration}
      description={description}
    ></TreatmentComponent>
  );

  const renderDescription = (currentCategory: TreatmentCategory): ReactNode => {
    const raw = pathOr(null, [`description`, `raw`], currentCategory);
    return !isNil(raw) && <div className="p-treatments__description">{documentToReactComponents(JSON.parse(raw))}</div>;
  };

  const renderTreatments = ({ treatments, image }: TreatmentCategory): ReactNode => (
    <div className="p-treatments__content">
      <ImageAsset cn="p-treatments__image" image={image.gatsbyImageData} title={image.description} />
      <div className="p-treatments__items">
        {treatments.map((treatment: Treatment): ReactNode => renderTreatment(treatment))}
      </div>
    </div>
  );

  return (
    <Layout cn="p-treatments">
      <SEO title={title} lang="nl" />
      <section className="p-treatments__wrapper u-wrapper">
        <h2 className="u-visibility-hidden">Ontdek onze behandelingen</h2>
        <header className="p-treatments__header">
          <div className="p-treatments__info">
            <h3 className="p-treatments__title u-heading-large u-ragoon">{currentCategory?.title}</h3>
            {renderCategories()}
          </div>
          {!isNil(currentCategory) && renderDescription(currentCategory)}
        </header>
        {!isNil(currentCategory) && renderTreatments(currentCategory)}
      </section>
    </Layout>
  );
};

export default TreatmentsPage;
