import { useStaticQuery, graphql } from "gatsby";

import { formatTreatmentsPageData } from "../../helpers/data/data.helper";

import { TreatmentsPageData } from "./useTreatmentsPageData.types";

export default (): TreatmentsPageData => {
  const { contentfulPagina } = useStaticQuery(
    graphql`
      query TreatmentsPage {
        contentfulPagina(slug: { eq: "e6f7667b-a6ba-4ded-8df3-6d015b3870da" }) {
          id
          title
          content {
            ... on ContentfulBehandelingCategorie {
              id
              title
              description {
                raw
              }
              image {
                title
                description
                gatsbyImageData(
                  quality: 100
                  placeholder: BLURRED
                  formats: AUTO
                  aspectRatio: 0.65
                  layout: FULL_WIDTH
                )
              }
              treatments {
                id
                title
                price
                duration
                description {
                  raw
                }
              }
              sys {
                contentType {
                  sys {
                    id
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  return formatTreatmentsPageData(contentfulPagina);
};
