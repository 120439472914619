import React from "react";
import { isNil } from "ramda";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

import { TreatmentProps } from "./Treatment.types";

export const Treatment: React.FC<TreatmentProps> = ({ title, description, price, duration }: TreatmentProps) => (
  <article className="m-treatment">
    <header className="m-treatment__header">
      <h4 className="m-treatment__title u-heading-medium u-gold">{title}</h4>
      <p className="m-treatment__info u-ragoon">
        {!isNil(price) && <span>€ {price}</span>}
        {!isNil(price) && !isNil(duration) && <span className="m-treatment__divider">&#124;</span>}
        {!isNil(duration) && <span>{`${duration} min`}</span>}
      </p>
    </header>
    {!isNil(description) && (
      <div className="m-treatment__content">{documentToReactComponents(JSON.parse(description.raw))}</div>
    )}
  </article>
);
